import { initHamburger } from './components/initHamburger';
import { initScrollToTop } from './components/initScrollToTop';
import { Modal } from './components/Modal';
import { initForms } from './components/initForms';
import { initAccordions } from './components/initAccordions';
import { initScrollLocker } from './assets/scrollLocker';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initFetchCheckoutAssetsAndCaching } from '../../../../Common/Resources/src_front/js/components/initFetchCheckoutAssetsAndCaching';
import { initSocialMediaSlider } from './components/sliders/initSocialMediaSlider';
import { initTestimonialsSlider } from './components/sliders/initTestimonialsSlider';
import { initRedirectToCheckout } from '../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

window.addEventListener('load', function () {
    initFetchCheckoutAssetsAndCaching();
});

function handleOnLoadPage() {
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initHashEmail();
    initScrollLocker();
    const modal = new Modal();
    initHamburger();
    initScrollToTop();
    initForms();
    initSocialMediaSlider();
    initTestimonialsSlider();
    initAccordions();
    initScrollToAnchor();
    initRedirectToCheckout();
}
